<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy2" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section
    v-else
    class="bg-agrogo-login"
    :style="styleObject(record?.img_head_url)"
  >
    <publish-button />
    <div class="container-bg-login-desktop">
      <div class="bg-agrogo-green-2 bg-agrogo-green-2-transparent">
        <div class="container container-forms pb-4 md:pb-12">
          <div class="flex flex-col">
            <div>
              <h2
                class="text-lg text-white font-nexa font-bold text-left mb-2 md:text-3-5xl md:mb-4"
              >
                ¡Hola!
              </h2>
              <p
                class="text-sm text-white font-nexa font-normal text-left md:text-2xl"
              >
                ¿Preparado para vender y comprar de manera fácil y rápida?
              </p>
              <div class="flex mt-4 md:mt-10 md:justify-around">
                <router-link
                  class="text-base text-agrogo-yellow-1 font-nexa font-bold md:text-2xl"
                  to="/inicio-de-sesion"
                  >Ingresar</router-link
                ><span class="text-white text-base mx-3 md:text-2xl md:mx-0"
                  >|</span
                ><router-link
                  class="text-base text-white hover:text-agrogo-yellow-1 font-nexa font-bold md:text-2xl"
                  to="/registro"
                  >Registrarse</router-link
                >
              </div>
            </div>
            <form class="mt-8" @submit.prevent="login">
              <input type="hidden" name="remember" value="true" />
              <div class="rounded-md shadow-sm">
                <div class="text-left">
                  <label
                    for="email-address"
                    class="text-xs text-white font-nexa font-normal block ml-2.5 mb-1 md:mb-2 md:text-base md:ml-5"
                    >Número de whatsapp</label
                  >
                  <div class="flex mb-4">
                    <div class="relative w-2/5 md:w-3/12 mr-2">
                      <img :src="img" class="img-code" alt="" />
                      <v-select
                        :options="codes"
                        v-model="selected"
                        :reduce="(option) => option.id"
                        class="style-chooser"
                        label="phone_code"
                      >
                        <template
                          #option="{ phone_code, path_img_iso_large, name }"
                        >
                          <div class="flex items-center justify-between">
                            <div class="flex">
                              <img
                                :src="path_img_iso_large"
                                :alt="phone_code"
                                class="mr-2"
                              />
                              <div class="font-medium">{{ phone_code }}</div>
                            </div>
                            <div class="font-medium">
                              <div v-if="name.length > 20">
                                {{ name.slice(0, 20) }}..
                              </div>
                              <div v-else>{{ name }}</div>
                            </div>
                          </div>
                        </template>
                        <template #no-options>Sin opciones</template>
                      </v-select>
                    </div>
                    <div class="relative w-3/5 md:w-9/12">
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                      >
                        <span
                          class="icon-telefono text-agrogo-black-1 text-lg"
                        ></span>
                      </div>
                      <input
                        v-model="formData.telephone"
                        id="telephone"
                        name="telephone"
                        type="number"
                        class="input-login"
                      />
                    </div>
                  </div>
                  <!-- <input
                    v-model="$v.formData.email.$model"
                    @blur="$v.formData.email.$touch()"
                    id="email-address"
                    name="email"
                    type="text"
                    class="input-login"
                    :class="{ 'error-form': $v.formData.email.$error }"
                  /> -->
                </div>
                <!-- <p
                  class="text-xs text-agrogo-yellow-2 font-nexa block ml-2.5 mb-1 text-left mt-1"
                  v-if="$v.formData.telephone.$error"
                >
                  {{ messageValidation($v.formData.telephone) }}
                </p>
                <p
                  class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                  v-else
                >
                  &nbsp;
                </p> -->
                <div class="text-left">
                  <label
                    for="password"
                    class="text-xs text-white font-nexa font-normal block ml-2.5 mb-1 md:mb-2 md:text-base md:ml-5"
                    >Contraseña</label
                  >
                  <div class="relative">
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-3"
                    >
                      <span
                        @click.prevent="isShow = !isShow"
                        class="icon-see text-agrogo-black-1 text-base cursor-pointer"
                      ></span>
                    </div>
                    <input
                      v-model="$v.formData.password.$model"
                      @blur="$v.formData.password.$touch()"
                      id="password"
                      name="password"
                      :type="isShow ? 'text' : 'password'"
                      class="input-login"
                      :class="{ 'error-form': $v.formData.password.$error }"
                    />
                  </div>
                </div>
                <p
                  class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                  v-if="$v.formData.password.$error"
                >
                  {{ messageValidation($v.formData.password) }}
                </p>
                <p
                  class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                  v-else
                >
                  &nbsp;
                </p>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center mb-5 md:ml-5">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="custom-checkbox-test"
                  />
                  <label
                    for="remember-me"
                    class="text-white text-xs font-dinpro font-normal block ml-3 md:text-base"
                  >
                    Recordar contraseña
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  class="btn-login flex justify-center items-center"
                  :disabled="isBusy || $v.$invalid"
                >
                  <div class="loader" v-if="isBusy" />
                  Iniciar sesión
                </button>
              </div>
              <div
                class="mt-4 text-left bg-agrogo-yellow-1 p-2"
                v-if="errors.length > 0"
              >
                <ul class="ul">
                  <li
                    class="text-agrogo-green-2 font-nexa text-sm mb-1"
                    v-for="(item, index) in errors"
                    :key="index"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </form>
            <div class="block mt-5 md:mt-6">
              <router-link
                to="/recuperacion-de-contrasena"
                class="text-xs text-agrogo-yellow-1 font-nexa font-bold hover:text-white md:text-lg"
              >
                ¿Olvidaste tu contraseña?
              </router-link>
            </div>
            <div class="block mt-5 md:mt-3.5">
              <span class="text-xs text-white font-nexa font-normal md:text-lg"
                >¿Aún no tienes una cuenta?</span
              >
              <router-link
                to="/registro"
                class="text-xs text-agrogo-yellow-1 font-nexa font-normal hover:text-white md:text-lg"
              >
                Regístrate
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import { mapActions, mapState } from "vuex";
// Mixins
import validationMixin from "@/mixins/validationMixin";
import alertMessageMixin from "@/mixins/alertMessageMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import metaMixin from "@/mixins/metaMixin";
// Vuelidate
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// Components
import PxHeader from "@/components/header/PxHeader";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  inject: ["provider"],
  name: "Login",
  mixins: [validationMixin, alertMessageMixin, loginRegisterMixin, metaMixin],
  metaInfo() {
    const me = this;
    return me.dataMeta;
  },
  data() {
    return {
      $v: useVuelidate(),
      formData: {
        // email: "",
        telephone: null,
        code_area_id: 1,
        password: "",
      },
      errors: [],
      repository: this.provider.authRepository,
      publishFarmRepository: this.provider.publishFarmRepository,
      codes: [],
      isBusy: false,
      isBusy2: true,
      isShow: false,
      record: null,
    };
  },
  components: {
    PxHeader,
    PublishButton,
  },
  validations() {
    return {
      formData: {
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        // email: {
        //   required,
        //   email,
        // },
        password: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    ...mapActions("user", ["loadAuthenticated"]),
    async login() {
      const me = this;
      const dataAditional = await generateHash();

      try {
        me.isBusy = true;
        const { data } = await me.repository.login({
          ...me.formData,
          ...dataAditional,
          dev0: process.env.VUE_APP_DEBUG_CUSTOM,
        });
        localStorage.setItem("access_token", data.token);
        localStorage.setItem("authenticated", true);
        me.loadAuthenticated(true);

        if (me.isPreLaunch) {
          me.$router.push({
            name: "publicar",
          });
        } else {
          const redirect = me.$route.query.redirect;
          if (redirect) {
            me.$router.push({
              name: redirect,
            });
          } else {
            if (me.$route.redirectedFrom) {
              const { name, params } = me.$route.redirectedFrom;
              me.$router.push({
                name,
                params,
              });
            } else {
              me.$router.push("/");
            }
          }
        }
      } catch (error) {
        const code = error.response.status;
        me.errors = [];
        if (code == 423) {
          const { response } = error.response.data;
          me.alertMenssage(response, 3000);
          me.$router.push({
            name: "VerifyCellPhone",
            query: { telephone: me.formData.telephone },
          });
        } else {
          const { message } = error.response.data;
          me.errors.push({
            name: message,
          });
        }
      } finally {
        me.isBusy = false;
      }
    },
    async loadData() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.publishFarmRepository.getAllNames("LOGIN", dataAditional);

        if (data?.metadata) {
          me.meta = data?.metadata;
          me.loadDataMeta();
        }
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy2 = false;
      }
    },
    styleObject(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajeaboutusdesktop.jpg");
      return { backgroundImage: `url(${img})` };
    },
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
    await me.loadCodeArea();
  },
};
</script>
